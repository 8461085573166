body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.section {
  height: 100vh;
  background-color: transparent;
  position: relative;
  width: 100%;
}

.section::before {
  animation: bounce 1s ease infinite;
  bottom: 8rem;
  color: #fff;
  content: '╲╱';
  font-size: 2rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 0.8;
  position: absolute;
  text-align: center;
  width: 6rem;
}

@keyframes bounce {
  50% {
    transform: translateY(-50%);
  }
}
@import url("https://fonts.googleapis.com/css?family=Barlow|Tomorrow:400,700&display=swap");
.overlay {
  position: absolute;
  left: 10%;
  bottom: 10%;
  z-index: 5;
  cursor: pointer;
  background-color: #12ff1a;
  color: var(--black-color);
  opacity: 1;
  padding: 10px;
  font-family: Tomorrow, sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  animation: fadeIn 1.2s;
  box-shadow: 0px 0px 10px 2px #12ff1a;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}